import React from 'react';
import {graphql} from 'gatsby';
import {Page} from '../components/Page';
import PageHeader from '../components/PageHeader';
import {Markdown} from '../components/Markdown';
import {Helmet} from "react-helmet";

export default function About({data: {tutorials}}) {
	return <Page>
		<Helmet>
			<title>{tutorials.titleSEO}</title>
			<meta name="description" content={tutorials.descriptionSEO} />
{/*			<link rel="canonical" href="https://www.icit.com.au/tutorials" />*/}
		</Helmet>
		<PageHeader>{tutorials.title}</PageHeader>
		<Markdown>
			{tutorials.bodyText}
		</Markdown>
		<Markdown>
			{tutorials.extraInfo}
		</Markdown>
	</Page>;
}

export const query = graphql`
    query {
        tutorials {
        	title
        	bodyText
        	extraInfo
			titleSEO
			descriptionSEO
        }
    }
`;
